import React from 'react'
import StatisticsList from '../Admin_Tour/StatisticsList';
import OffersList from '../Admin_Tour/OffersList';
import Subtitle from '../../../shared/Subtitle';
import Touroperator from '../Admin_Tour/TourOperator';
import CompanyDetails from '../Admin_Tour/CompanyDetails';
import UserDetails from '../Admin_Tour/UserDetails';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Update_Gallery from '../Admin_Tour/Update_Gallery';
import Get_Tour from '../Admin_Tour/Get_Tour';
import GetCompany_Tour from '../../Company/GetCompany_Tour';
import BookingDetails from '../BookingDetails';
import MediaDetails from '../Admin_Tour/MediaDetails';
const Admin_Dashboard = () => {
 const navigate=useNavigate();
      return (
    <div className='container'>     
<div>
      <Subtitle subtitle={"Tours"} />
<Get_Tour />
</div>
<div>
      <Subtitle subtitle={"Booking Details"} />
<h1>Booking Details</h1>
<BookingDetails />
</div>
     <div>

      <Subtitle subtitle={"Company "} />
<h1>Companies Detail</h1>
<CompanyDetails />
</div>
<div>
      <Subtitle subtitle={"User's "} />
<h1>User's Detail</h1>
<UserDetails />
</div>
      <div>
      <Subtitle subtitle={"Your journey, your story!"} />   
<h1>Tour Operator List</h1>
<Touroperator />
</div>            
      <div>
      <Subtitle subtitle={"What we serve"} />            
<h1>Offers List</h1>
<OffersList />
</div>
      <div>
      <Subtitle subtitle={"Experience"} />
<h1>Statistics List</h1>
<StatisticsList />
</div>

<div>
      <Subtitle subtitle={"Gallery"} />
<h1>Gallery</h1>
<Update_Gallery />
</div>
<div>
      <Subtitle subtitle={"Media"} />
<h1>Media</h1>
<MediaDetails />
</div>

    </div>
  )
}

export default Admin_Dashboard
