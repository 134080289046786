import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../styles/tour-details.css";
import { Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { setTourid } from "../components/Redux Toolkit/authSlice";
import Booking from "../components/Booking/Booking";
import Newsletter from "../shared/Newsletter";
import CircleComponent from "../components/CircleComponent";
import Review from "../shared/Review";

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in Error Boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const TourDetails = () => {
  const tourdetailid = useSelector((state) => state.auth.tourdetailid);
  const dispatch = useDispatch();
  const [tourData, setTourData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchToursAndSchedules();
  }, [tourdetailid]);

  const fetchToursAndSchedules = async () => {
    try {
      const response = await axios.get(
        `https://www.tripwaly.com/api/tour/schedule/get/${tourdetailid}`
      );
      setTourData(response.data);
    } catch (err) {
      console.error("Error fetching tours and schedules:", err);
      toast.error("Error fetching tours and schedules");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Dispatch tour ID once the tourData is set
  useEffect(() => {
    if (tourData?._id) {
      dispatch(setTourid(tourData._id));
    }
  }, [tourData, dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const { tourid } = tourData || {};

   // Function to format time to 12-hour format
   const formatTimeTo12Hour = (time24) => {
    const [hour, minute] = time24.split(':');
    const hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? 'PM' : 'AM';
    const hour12 = hourInt % 12 || 12; // Convert hour to 12-hour format
    return `${hour12}:${minute} ${period}`;
};

  return (
    <div className="container-fluid">
      <Helmet>
        <title>TourDetails - Tripwaly</title>
        <meta
          name="description"
          content="Welcome to the homepage of My Website."
        />
        <meta name="keywords" content="home, my website, seo keywords" />
      </Helmet>

      <ToastContainer />
      <section>
        <Container>
          <Row>
            <Col lg="8">
              <div className="tour__content">
                {tourid?.images?.length > 0 && (
                  <img
                    src={`https://www.tripwaly.com/${`${tourid.images[0]}`}
                    `} alt={tourid.images[0]}
                    className="tour-image"
                  />
                )}

                {/* Tour Details Card */}
                <div className="tour-details-card my-4">
                  <h3 className="tour-details-heading">{tourid.tocity} Tour Details</h3>
                  <div className="tour-details-content">
                    <p>
                      To City: <strong className="text-bold">{tourid.fromcity}</strong>
                    </p>
                   
                    <p>
                      Duration: <strong>{tourid.duration}</strong>
                    </p>
                    <div className="d-flex  checking">
                     <p> From date: <strong>{tourid.fromdate.slice(0, 10)}   </strong></p>
                      
                    <p>  To date: <strong>{tourid.todate.slice(0, 10)}</strong></p>
                    <p>Time: <strong>{formatTimeTo12Hour(tourid.time)}</strong></p>
                    </div>
                  
                    <p>
                      Available seats: <strong>{tourid.availableseats}</strong>
                    </p>
                    <p>
                      Price: <strong>{tourid.price} PKR</strong>{" "}
                      <span className="text-warning">per person</span>
                    </p>
                    <p>
                      Description: <strong>{tourid.description}</strong>
                    </p>
                  </div>
                </div>

                <CircleComponent tourscheduleid={tourData._id} />

                {/* Booking component for small screens above Review */}
                <Col xs="12" lg="12" className="d-block d-lg-none">
                  <Booking
                    tourscheduleid={tourData._id}
                    tourid={tourid._id}
                    tour={tourid}
                  />
                </Col>

                <Review tourscheduleid={tourData._id} />
              </div>
            </Col>

            <Col lg="4" className="d-none d-lg-block">
              {/* Booking component for large screens */}
              <Booking
                tourscheduleid={tourData._id}
                tourid={tourid._id}
                tour={tourid}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Newsletter />
    </div>
  );
};

// Wrap TourDetails component with ErrorBoundary
const TourDetailsWithErrorBoundary = () => (
  <ErrorBoundary>
    <TourDetails />
  </ErrorBoundary>
);

export default TourDetailsWithErrorBoundary;
