import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "../../shared/tour-card.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

import { useDispatch } from "react-redux";
import { Tourdetailid } from "../Redux Toolkit/authSlice";
const FeaturedTourList = () => {
  const dispatch = useDispatch();
  const [tourData, setTourData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchToursAndSchedules();
  }, []);

  const fetchToursAndSchedules = async () => {
    try {
      const tourResponse = await axios.get(
        `https://www.tripwaly.com/api/tour/schedule/get`
      );

      setTourData(tourResponse.data); // Set the fetched tours data
    } catch (err) {
      console.error("Error fetching tours and schedules:", err);
      toast.error("Error fetching tours and schedules");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

 
  return (
    <div className="tour__card">
  <div className="row">
    {tourData.map((tour, index) =>
      tour.tourid.availableseats === 0 ? null : (
        <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <Card className="d-flex h-100">
            {/* Wrap the card content in a Link */}
            <Link
              to={`/tours/${tour.tourid.slug}`}
              onClick={() => dispatch(Tourdetailid(tour._id))}
              className="card-link-wrapper"
            >
              {/* Image Section */}
              {tour.tourid.images && tour.tourid.images.length > 0 && (
                <div>
                  <span className="tour__img">
                    <img
                      src={`https://www.tripwaly.com/${`${tour.tourid.images[0]}`}
                      `} alt={`Image ${tour.tourid.images[0]}`}
                      className="tour__image"
                    />
                  </span>
                </div>
              )}
              <CardBody className="d-flex flex-column justify-content-between">
                <div className="card__top d-flex align-items-center justify-content-between">
                  <span className="tour__location d-flex align-items-center gap-1">
                    <i className="ri-map-pin-line"></i>
                    <span className="truncate-text text-semibold">{`${tour.tourid.fromcity} to ${tour.tourid.tocity}`}</span>
                  </span>
                  <span className="tour__rating d-flex align-items-center gap-1">
                    <i className="ri-star-fill"></i>
                                         <span className="text-semibold">{tour.rating} reviews</span>
                  </span>
                </div>
                <h5 className="tour__title ">
                  {tour.tourid.title.length > 31 ? (
                    `${tour.tourid.title.slice(0, 28)}...`
                  ) : (
                    tour.tourid.title
                  )}
                </h5>

                {/* Date and Time Section */}
                <div className="tour__date-time d-flex align-items-center justify-content-between gap-3 mt-2">
                  <span className="tour__date d-flex align-items-center gap-1">
                    <i className="ri-calendar-line"></i>
                    <span className="text-semibold">
                      {new Date(tour.tourid.fromdate).toLocaleDateString()}
                    </span>
                  </span>
                  <span className="tour__time d-flex align-items-center gap-1">
                    <i className="ri-time-line"></i>
                    <span className="text-semibold">
                      {tour.tourid.time
                        ? new Date(
                            `${new Date().toISOString().split("T")[0]}T${
                              tour.tourid.time
                            }`
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "Time not available"}
                    </span>
                  </span>
                </div>

                <div className="card__bottom d-flex align-items-center justify-content-between mt-3">
                  <h5>
                    PKR {tour.tourid.price}
                    <span>/person</span>
                  </h5>
                  <button className="btn btn-sm bookings__btn">
                <Link
                  to={`/tours/${tour.tourid.slug}`}
                  onClick={() => dispatch(Tourdetailid(tour._id))}
                >
                  Book Now
                </Link>
              </button>
           
                </div>
              </CardBody>
            </Link>

            {/* Book Now Button outside the main Link */}
            <div className="">
            </div>
          </Card>
        </div>
      )
    )}
  </div>
</div>

  );
};

export default FeaturedTourList;
