import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "../styles/cities.css";
import axios from "axios";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS CSS
import { ToastContainer, toast } from 'react-toastify';

const Cities = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cities, setCities] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Track if there's more data to load
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const limit = 10; // Define the limit of cities per page

  // Fetch cities based on the page number
  const fetchCities = async (page) => {
    try {
      setLoading(true);
      const res = await axios.get(`https://www.tripwaly.com/api/tour/get/tourcity?page=${page}&limit=${limit}`);
      const newCities = res.data;

      // Log response to check for duplicate data
      console.log(newCities);

      // Ensure no duplicate cities by filtering out cities already in the state
      setCities((prevCities) => {
        const cityIds = prevCities.map(city => city._id);
        const uniqueNewCities = newCities.filter(city => !cityIds.includes(city._id));
        return [...prevCities, ...uniqueNewCities];
      });

      // If the data returned is less than the limit, it means there are no more cities to load
      if (newCities.length < limit || newCities.length === 0) {
        setHasMore(false); // Stop further loading
      }
    } catch (err) {
      toast.error("Tour city data not fetched");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCities(page);
  }, [page]);

  useEffect(() => {
    const texts = [
      "Select Your Favourite Destination.",
      "Select Your Favourite Destination.",
      "Select Your Favourite Destination."
    ];

    const changeText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    const interval = setInterval(() => {
      changeText();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    AOS.refresh(); // Refresh AOS animations when the component updates
  }, [cities]);

  const filteredCities = cities.filter((city) => {
    return (
      city.fromcity.toLowerCase().includes(searchTerm.toLowerCase()) ||
      city.totaltours.toString().includes(searchTerm.toLowerCase())
    );
  });

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight &&
      hasMore && !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);

  return (
    <div className=" container-fluid">

<Helmet>
        <title>Tours - Tripwaly</title>
        <meta
          name="description"
          content="Welcome to the homepage of My Website."
        />
        <meta name="keywords" content="home, my website, seo keywords" />
      </Helmet>

      <ToastContainer />
      <div className="background-image-cities">
        <div className="animated-text">
          {["Select Your Favourite Destination.", "Select Your Favourite Destination.", "Select Your Favourite Destination."].map((text, index) => (
            <div key={index} className={`text ${currentTextIndex === index ? 'active' : 'hidden'}`}>
              {text}
            </div>
          ))}
        </div>
      </div>

      {/* Search Bar */}
      <div className="cities__search-section mt-5">
        <input
          type="search"
          className="cities__search-bar"
          placeholder="Search here..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Cities Cards */}
      <div className="cities__cards container">
        {filteredCities.map((city) => (
          <div 
            key={city._id} 
            className="cities__card mt-1"
            data-aos="fade-up" // AOS attribute for animation
          >
            <div className="cities__card-image-wrapper">
              {city.image && city.image.length > 0 ? (
                <img src={`https://www.tripwaly.com/${city.image[0]} `} alt={city.fromcity} className="cities__card-image" />
              ) : (
                <img src={`https://www.tripwaly.com/$"/default-city.jpg" `} alt={city.fromcity} className="cities__card-image" />
              )}
            </div>
            <div className="cities__card-content">
              <h2 className="cities__card-title">{city.fromcity}</h2>
              <p className="cities__card-tours">Number of Tours: <strong>{city.totaltours}</strong></p>
              <button 
                className="cities__card-button" 
                onClick={() => navigate(`/cities/tours/${city.fromcity.toLowerCase()}`)}
              >
                View Tours
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Loading spinner */}
      {loading && (
        <div className="loading-spinner-wrapper">
          <div className="loading-spinner"></div>
        </div>
      )}

      {/* No more data message */}
      {!hasMore && !loading && (
      <p></p>
      )}
    </div>
  );
};

export default Cities;
