import React from 'react'

const SearchResultList = () => {
  return (
    <div>
      SearchResultList
    </div>
  )
}

export default SearchResultList
