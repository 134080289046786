import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../Admincss/GetTourDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UpdateTour from './Updatetour';

const GetToursDetails = () => {
    const navigate=useNavigate();
    const { id } = useParams();
    const [tourSchedules, setTourSchedules] = useState([]);
    const [show, setShow] = useState(false); // Manage modal visibility

    const handleToggle = () => setShow(!show);
   
    useEffect(() => {
        const fetchTourSchedules = async () => {
            try {
                const response = await axios.get('https://www.tripwaly.com/api/tour/schedule/get');
                const filteredSchedules = response.data.filter(schedule => schedule._id === id);
                setTourSchedules(filteredSchedules);
            } catch (error) {
                console.error('Error fetching tour schedules', error);
            }
        };
        fetchTourSchedules();
    }, [id]);

    const UpdateTourSchedule=()=>{
        navigate(`/update/tour/schedule/${id}`);
        }
    return (
        <div className="get-tour-container">
            {tourSchedules && tourSchedules.map(scheduleGroup => (
                <div key={scheduleGroup._id} className="tour-schedule">
                    <div className="tour-schedule-content">
                        {/* Left Section - Tour Images and Details */}
                        <div className="tour-details">
                            <div className="tour-images">
                                {scheduleGroup.tourid.images && scheduleGroup.tourid.images.length > 0 ? (
                                    scheduleGroup.tourid.images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={`https://www.tripwaly.com/${image}
                                            `} alt={`Tour ${scheduleGroup.tourid.title} Image ${index}`}
                                            className="tour-image"
                                        />
                                    ))
                                ) : (
                                    <p>No images available for this tour.</p>
                                )}
                            </div>
                            <div className="details-content">
                                <h4>Tour Details:</h4>
                                <p><strong>Title:</strong> {scheduleGroup.tourid.title}</p>
                                <div className=''>
                                    <p><strong>From City:</strong> {scheduleGroup.tourid.fromcity}</p>
                                    <p><strong>To City:</strong> {scheduleGroup.tourid.tocity}</p>
                               
                                </div>
                                <div className=''>
                                   <p> <strong>From Date:</strong> {scheduleGroup.tourid.fromdate.slice(0,10)}</p>
                                    <p><strong>To Date:</strong> {scheduleGroup.tourid.todate.slice(0,10)}</p>
                                   <p> <strong>Time:</strong> {scheduleGroup.tourid.time}</p>
                                    
                                    </div>
                                <p><strong>Duration:</strong> {scheduleGroup.tourid.duration}</p>
                                <p><strong>Price:</strong> {scheduleGroup.tourid.price}</p>
                                {scheduleGroup.tourid.category.map((val, index) => (
                                    <p key={index}><strong>Category:</strong> {val.title}</p>
                                ))}
                                <p><strong>Description:</strong> {scheduleGroup.tourid.description}</p>
                               
                                <button className="update-button" onClick={handleToggle}>Update Tour</button>
                            </div>
                        </div>

                        {/* Right Section - Schedule Details */}
                        <div className="schedule-details">
                        <div className="tour-images">
                             
                        <div className="tour-images">
                                {scheduleGroup.schedules.slice(0,1).map(schedule=>
                        <img
                                            src={`https://www.tripwaly.com/${schedule.images}
                                            `} alt={`Tour ${scheduleGroup.tourid.title}`}
                                            className="tour-image"
                                        />
                                 )}
                            </div>
                               </div>
                            <h4>Schedules:</h4>
                            {tourSchedules.map(val => (
                                <div key={val._id}>
                                    <p><strong>Rating:</strong> {val.rating}</p>
                                    <p><strong>Featured:</strong> {val.featured ? 'true' : 'false'}</p>
                                    <p><strong>Tour Status:</strong> {val.active ? 'Active' :'Not Active'}</p>
                                </div>
                            ))}
                            {scheduleGroup.schedules && scheduleGroup.schedules.map(schedule => (
                                <div key={schedule._id} className="schedule-item">
                                    <p><strong>Title:</strong> {schedule.title}</p>
                                    <p><strong>Time:</strong> {schedule.time}</p>
                                    <p><strong>Date:</strong> {schedule.date.slice(0, 10)}</p>
                                    <p><strong>City:</strong> {schedule.city}</p>
                                    <p><strong>Description:</strong> {schedule.description}</p>
                                  
                                </div>
                            ))}
                            <button className="update-button" onClick={()=>UpdateTourSchedule()}>Update Tour Schedule</button>
                        </div>
                    </div>
                </div>
            ))}
               {/* Modal Component from reactstrap */}
               <Modal isOpen={show} toggle={handleToggle} >
                <ModalHeader toggle={handleToggle}>Update Tour</ModalHeader>
                <ModalBody>
                    <UpdateTour  tourdata={tourSchedules} />

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handleToggle}>
                        Close
                    </Button>
                    </ModalFooter>
            </Modal>
        </div>
    );
};

export default GetToursDetails;
