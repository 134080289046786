import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import '../Admin/Admincss/CompanySection.css';

const CompanyDetails = () => {
  const detail = useSelector((state) => state.auth.login.companyName);
  const [getCompany, setCompany] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({
    name: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    companyAddress: '',
    description: '',
    active: false,
  });
  const [newImage, setNewImage] = useState(null); // State to hold new image file

  const CompanyDetail = async () => {
    try {
      const response = await axios.get("https://www.tripwaly.com/api/company/get");
      const companies = response.data;
      const filteredCompanies = companies.filter((val) => val.companyName === detail);
      setCompany(filteredCompanies);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    CompanyDetail();
  }, []);

  const openModal = (company) => {
    setSelectedCompany(company);
    setNewImage(null); // Reset new image on modal open
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedCompany((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]); // Set the new image file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    // Append all company details to formData
    Object.keys(selectedCompany).forEach((key) => {
      formData.append(key, selectedCompany[key]);
    });

    // Append new image if it exists
    if (newImage) {
      formData.append('images', newImage);

    }

    try {
      const response = await axios.put(
        `https://www.tripwaly.com/api/company/update/${selectedCompany._id}`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Set content type for form data
          }
        }
      );
      setCompany((prevCompanies) =>
        prevCompanies.map((company) =>
          company._id === response.data._id ? response.data : company
        )
      );
      closeModal();
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  return (
    <section className="company-section">
      <div className="company-cards">
        {getCompany &&
          getCompany.map((company, index) => (
            <div key={index} className='p-3'>
              <div className="company-images" >
                <img
                  src={`https://www.tripwaly.com/${company.companyimage} `}
                  alt={company.name}
                  onClick={() => openModal(company)} // Open modal on image click
                />
              </div>
              <div className="company-details">
                <h2 className="company-names">{company.companyName}</h2>
                <p>Owner Name: <strong>{company.name}</strong></p>
                <p>Email: <strong>{company.email}</strong></p>
                <p>Phone no.: <strong>{company.phoneNumber}</strong></p>
                <p>Company Address: <strong>{company.companyAddress}</strong></p>
                <p className="company-descriptions">{company.description}</p>
                <button className="update-buttons" onClick={() => openModal(company)}>Update</button>
              </div>
            </div>
          ))}
      </div>

      {/* Modal for Updating Company */}
      {isModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <h2>Update Company</h2>
      <form onSubmit={handleSubmit}>
        <div className="image-preview">
          <img
            src={
              newImage
                ? URL.createObjectURL(newImage) // Show new image preview
                : `https://www.tripwaly.com/${selectedCompany.companyimage}` // Show current image
            }
            alt={selectedCompany.name}
            onClick={() => document.getElementById('imageUpload').click()} // Trigger file input on image click
          />
        </div>

        {/* Hidden file input triggered on image click */}
        <input
          id="imageUpload"
          type="file"
          accept="image/*"
          style={{ display: 'none' }} // Hide the input element
          onChange={handleImageChange} // Handle image change
        />

        {/* Other input fields */}
        <label>
          Owner Name:
          <input
            type="text"
            name="name"
            value={selectedCompany.name}
            onChange={handleChange}
          />
        </label>
        <label>
          Company Name:
          <input
            type="text"
            name="companyName"
            value={selectedCompany.companyName}
            onChange={handleChange}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={selectedCompany.email}
            onChange={handleChange}
          />
        </label>
        <label>
          Phone Number:
          <input
            type="tel"
            name="phoneNumber"
            value={selectedCompany.phoneNumber}
            onChange={handleChange}
          />
        </label>
        <label>
          Company Address:
          <input
            type="text"
            name="companyAddress"
            value={selectedCompany.companyAddress}
            onChange={handleChange}
          />
        </label>
        <label>
          Description:
          <textarea
            name="description"
            value={selectedCompany.description}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Save Changes</button>
        <button type="button" onClick={closeModal}>Cancel</button>
      </form>
    </div>
  </div>
)}

    </section>
  );
};

export default CompanyDetails;
