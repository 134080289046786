import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip } from 'reactstrap';
import "../styles/circleComponent.css";

const CircleComponent = ({ tourscheduleid }) => {
  const [tourData, setTourData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredCircleId, setHoveredCircleId] = useState(null);

  useEffect(() => {
    fetchToursAndSchedules();
  }, [tourscheduleid]);

  const fetchToursAndSchedules = async () => {
    try {
      const response = await axios.get(`https://www.tripwaly.com/api/tour/schedule/get/${tourscheduleid}`);
      setTourData(response.data);
    } catch (err) {
      console.error('Error fetching tours and schedules:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="cardd-container">
      <div className="tour-road-container">
        <svg className="s-road" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path d="M 10 30 C 40 70, 60 10, 90 90" stroke="#333" strokeWidth="4" fill="transparent" />
        </svg>

        {tourData.schedules.map((schedule, index) => {
          let top, left;
          switch (index) {
            case 0: top = 20; left = 7; break;
            case 1: top = 35; left = 30; break;
            case 2: top = 40; left = 55; break;
            case 3: top = 55; left = 73; break;
            case 4: top = 80; left = 85; break;
            default: top = 50; left = 50; break;
          }

          return (
            <div
              className="circle"
              key={schedule._id}
              style={{ top: `${top}%`, left: `${left}%` }}
              onMouseEnter={() => setHoveredCircleId(schedule._id)}
              onMouseLeave={() => setHoveredCircleId(null)}
              id={`circle-${schedule._id}`}
            >
              <img src={`https://www.tripwaly.com/${schedule.images} `} alt={schedule.city} />
              <div className="circle-content">
                <h3>{schedule.city}</h3>
                <h3>{schedule.time}</h3>
              </div>

              <Tooltip
                isOpen={hoveredCircleId === schedule._id}
                target={`circle-${schedule._id}`}
                className="custom-tooltip"
                placement="right"
              >
                <h4>{schedule.city}</h4>
                <div className='d-flex justify-content-around'>
                  <p>Date: {schedule.date.slice(0,10)} </p>
                  <p>Time: {schedule.time}</p>
                  </div>
                {schedule.description && <p>{schedule.description}</p>}
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CircleComponent;
