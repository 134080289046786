import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../Admincss/UpdateTourSchedule.css'; // Make sure to import the CSS file

const UpdateTourSchedule = () => {
    const { id } = useParams();
    const [state, setState] = useState({
        schedules: [],
        featured: false,
        active: false,
    });

    useEffect(() => {
        const fetchTourData = async () => {
            try {
                const response = await axios.get(`https://www.tripwaly.com/api/tour/schedule/get/${id}`);
                setState({
                    schedules: response.data.schedules || [],
                    featured: response.data.featured || false,
                    active: response.data.active || false,
                });
            } catch (error) {
                console.error("Error fetching tour data:", error.message);
            }
        };
        fetchTourData();
    }, [id]);

    const addSchedule = () => {
        setState(prevState => ({
            ...prevState,
            schedules: [
                ...prevState.schedules,
                { title: '', description: '', time: '', date: '', city: '', lat: '', long: '', images: [] },
            ],
        }));
    };

    const removeSchedule = (index) => {
        setState(prevState => {
            const updatedSchedules = [...prevState.schedules];
            updatedSchedules.splice(index, 1);
            return { ...prevState, schedules: updatedSchedules };
        });
    };

    const handleScheduleChange = (index, event) => {
        const { name, value } = event.target;
        setState(prevState => {
            const updatedSchedules = [...prevState.schedules];
            updatedSchedules[index][name] = value;
            return { ...prevState, schedules: updatedSchedules };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const payload = {
                schedules: state.schedules,
                featured: state.featured,
                active: state.active,
            };

            await axios.put(`https://www.tripwaly.com/api/tour/schedule/update/${id}`, payload);
            alert("Tour schedule updated successfully!");
        } catch (error) {
            console.error("Error updating tour schedule:", error.message);
            alert("Failed to update tour schedule.");
        }
    };

    return (
        <div className="update-schedule-container">
            <h2 className="title">Update Tour Schedule</h2>
            <form onSubmit={handleSubmit} className="update-schedule-form">
                <div className="checkbox-group">
                
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={state.featured}
                            onChange={() => setState(prevState => ({ ...prevState, featured: !prevState.featured }))}
                        />
                        Featured
                    </label>
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={state.active}
                            onChange={() => setState(prevState => ({ ...prevState, active: !prevState.active }))}
                        />
                        Active
                    </label>
                </div>

                <h3 className="subtitle">Schedules</h3>
                {state.schedules.map((schedule, index) => (
                    <div key={index} className="schedule-item">
                 <img
                                            src={`https://www.tripwaly.com/${schedule.images}
                                            `} alt='error image not found'
                                            className="tour-image"
                                        />
                        <input
                            type="text"
                            name="title"
                            value={schedule.title || ''}
                            onChange={(e) => handleScheduleChange(index, e)}
                            placeholder="Title"
                            className="input-field"
                        />
                        <input
                            type="text"
                            name="description"
                            value={schedule.description || ''}
                            onChange={(e) => handleScheduleChange(index, e)}
                            placeholder="Description"
                            className="input-field"
                        />
                        <input
                            type="time"
                            name="time"
                            value={schedule.time || ''}
                            onChange={(e) => handleScheduleChange(index, e)}
                            className="input-field"
                        />
                        <input
                            type="date"
                            name="date"
                            value={schedule.date || ''}
                            onChange={(e) => handleScheduleChange(index, e)}
                            className="input-field"
                        />
                        <input
                            type="text"
                            name="city"
                            value={schedule.city || ''}
                            onChange={(e) => handleScheduleChange(index, e)}
                            placeholder="City"
                            className="input-field"
                        />
                        <button type="button" onClick={() => removeSchedule(index)} className="remove-button">
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" onClick={addSchedule} className="add-button">
                    Add Schedule
                </button>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
};

export default UpdateTourSchedule;
