import React from 'react'
import Subtitle from '../../shared/Subtitle';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Get_Tour from '../Admin/Admin_Tour/Get_Tour';
import BookingDetails from '../Admin/BookingDetails';
import GetCompany_Tour from './GetCompany_Tour';
import CompanySection from './CompanyDetails';

const Company_Dashboard = () => {
const navigate=useNavigate();
      return (
    <div className='container'>
      <div>
      <Subtitle subtitle={"Company"} />
      <h1>Company Details</h1>
      <CompanySection/>
            </div>     
<div>
      <Subtitle subtitle={"Tours"} />
      <h1>Tour Details</h1>
<GetCompany_Tour />
</div>
<div>
<Subtitle subtitle={"Booking Details"} />
    
<h1>Booking Details</h1>
<BookingDetails />
</div>
<Subtitle subtitle={"Booking Details"} />
<button onClick={()=>navigate('/admin/dashboard')}>Admin Dashboard</button>
    </div>
  )
}

export default Company_Dashboard
