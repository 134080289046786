import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Button } from "reactstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import "./header.css";

const nav__links = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/companies",
    display: "Companies",
  },
  {
    path: "/cities",
    display: "Tours",
  },
];

const Header = () => {
  const headerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();
    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  }, []);

  const toggleMobileMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleUserIconClick = () => {
    navigate("/login"); // Navigate to the login page
  };

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav__wraper d-flex align-items-center justify-content-between">
            {/* ========== logo ========== */}
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
            {/* ========== logo end ========== */}

            {/* ========= menu start ========= */}
            <div className={`navigation ${isMenuOpen ? "show__menu" : ""}`}>
              <ul className="menu d-flex align-items-center gap-5">
                {nav__links.map((item, index) => (
                  <li className="nav__item" key={index}>
                    <NavLink
                      className={(navClass) =>
                        navClass.isActive ? "active__link" : ""
                      }
                      to={item.path}
                      onClick={() => setIsMenuOpen(false)} // Close menu after clicking a link
                    >
                      {item.display}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            {/* ========= menu end ========= */}

            {/* Right section: Display user icon on mobile */}
            <div className="nav__right d-flex align-items-center gap-4">
              <div className="nav__btns d-none d-md-flex align-items-center gap-4">
                <Button className="btn secondary__btn">
                  <Link to="/login">Login</Link>
                </Button>
                <Button className="btn primary__btn">
                  <Link to="/signup">Signup</Link>
                </Button>
              </div>

              {/* User icon for mobile */}
              <span
                className="user__menu d-md-none"
                onClick={handleUserIconClick}
              >
                <i className="ri-user-line"></i>
              </span>

              {/* Hamburger menu for mobile */}
              <span
                className="mobile__menu d-md-none"
                onClick={toggleMobileMenu}
              >
                <i
                  className={isMenuOpen ? "ri-close-line" : "ri-menu-line"}
                ></i>
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
