import './common-section.css'
import { Container, Row, Col } from 'reactstrap'
import React, { useState, useEffect } from "react";

const CommonSection = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const texts = [
      "All Tours in Pakistan.",
      "All Tours in Pakistan.",
    ];

    const changeText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    const interval = setInterval(() => {
      changeText();
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(interval);
  }, []);
  return (
    
    <Container fluid>
    <Row>
      <Col lg='12'>
        <div className="background-image-tours">
          <div className="animated-text">
            <div className={`text ${currentTextIndex === 0 ? 'active' : 'hidden'}`}>
              All Tours in Pakistan.
            </div>
            <div className={`text ${currentTextIndex === 1 ? 'active' : 'hidden'}`}>
              All Tours in Pakistan.
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
  
  )
}

export default CommonSection
