import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "../styles/signup.css"; // You can style it as you prefer

const Signup = () => {
  const navigate = useNavigate();

  const handleUserSignup = () => {
    navigate("/user/register");
  };

  const handleCompanySignup = () => {
    navigate("/company/register");
  };

  return (
    <section className="signup-page">
      <Container>
        <Row>
          <Col lg="12" className="text-center">
            <h1 className="mb-4">Welcome to Sign up Page</h1>
            <p className="lead">
              Please select whether you want to sign up as a user or as a company.
            </p>
            <div className="d-flex justify-content-center gap-4 mt-5">
              <Button
                className="btn btn-primary"
                size="lg"
                onClick={handleUserSignup}
              >
                User Signup
              </Button>

              <Button
                className="btn btn-secondary"
                size="lg"
                onClick={handleCompanySignup}
              >
                Company Signup
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Signup;
