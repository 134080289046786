import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../Admincss/tourSchedule.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
const Create_TourSchedule = () => {
  const navigate=useNavigate();
  const tourId=useSelector((state)=>state.auth.tourid);
  const [tourid, setTourid] = useState(tourId);
  const [featured, setFeatured] = useState(false);
  const [active, setActive] = useState(false);
  const [schedules, setSchedules] = useState([
    { title: '', description: '', time: '', city: '', lat: '', long: '', date:'' },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleScheduleChange = (index, event) => {
    const { name, value, files } = event.target;
    const newSchedules = [...schedules];

    if (name === "lat" || name === "long") {
      newSchedules[index][name] = parseFloat(value);
    } else {
      newSchedules[index][name] = value;
    }

    setSchedules(newSchedules);
  };

  const handleAddSchedule = () => {
    setSchedules([...schedules, { title: '', description: '', time: '', city: '', lat: '',date:'', long: '' }]);
  };

  const handleRemoveSchedule = () => {
    if (schedules.length > 1) {
      setSchedules(schedules.slice(0, -1)); // Remove the last item from the array
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('tourid', tourid);
    formData.append('featured', featured);
    formData.append('active', active);
    if (selectedFile) {
      formData.append('images', selectedFile);
    }
    schedules.forEach((schedule, index) => {
      formData.append(`schedules[${index}][title]`, schedule.title);
      formData.append(`schedules[${index}][description]`, schedule.description);
      formData.append(`schedules[${index}][time]`, schedule.time);
      
      formData.append(`schedules[${index}][date]`, schedule.date);
      formData.append(`schedules[${index}][city]`, schedule.city);
      
      formData.append(`schedules[${index}][lat]`, schedule.lat);
      formData.append(`schedules[${index}][long]`, schedule.long);
      if (schedule.images) {
        formData.append(`schedules[${index}][images]`, schedule.images); // Append file
      }
    });

    try {
      const response = await axios.post('https://www.tripwaly.com/api/tour/schedule/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate("/admin/dashboard");
    } catch (error) {
      alert('Error creating tour schedule:', error.response.data);
    }
  };
  const fileChangeHandler = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit} className='container'>
      {schedules.map((schedule, index) => (
  <div key={index} className="mb-4">
    <h3>Schedule {index + 1}</h3>
    
    <div className="row">
      <div className="col-md-6">
        <label>Title:</label>
        <input
          name="title"
          value={schedule.title}
          className="form-control"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
      <div className="col-md-6 mb-3">
      <label>Description</label>
            <textarea
          name="description"
          value={schedule.description}
          className="form-control"
          onChange={(e) => handleScheduleChange(index, e)}
          required
          />
             </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <label>Time:</label>
        <input
          type="time"
          name="time"
          value={schedule.time}
          className="form-control"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
      <div className="col-md-6">
        <label>Date:</label>
        <input
          type="date"
          name="date"
          value={schedule.date}
          className="form-control"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <label>City:</label>
        <input
          name="city"
          value={schedule.city}
          className="form-control"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
      <div className="col-md-6">
  <label>Image:</label>
  <div className="mt-3">
    <input
      type="file"
      id="file-input"
      onChange={fileChangeHandler}
      style={{ display: 'none' }} // Hide the default file input
    />
    <label htmlFor="file-input" className="custom-file-upload">
    <FontAwesomeIcon icon={faUpload} className="upload-icon" /> Upload Image
    </label>
  </div>
</div>

    </div>
  </div>
))}

      <button type="button" className="btn btn-secondary" onClick={handleAddSchedule}>Add Schedule</button>

    {schedules.length > 1 && (
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={handleRemoveSchedule}
        >
          Remove Schedule
        </button>
      )}  
      <button type="submit" className="btn btn-primary mt-1 mx-2">Create Tour Schedule</button>
    </form>
  );
};

export default Create_TourSchedule;
