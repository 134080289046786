// src/components/Create_Tour.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setTourid } from '../../Redux Toolkit/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import "../Admincss/CreatTour.css"
import { useSelector } from 'react-redux';
const categorydata=["Cultural", "Adventure", "Religious", "Leisure", "Event", "Historical", "Wildlife", "Beach", "Eco-Tourism", "Mountain/Climbing", "City Tours", "Rural Exploration", "Photography", "Food & Culinary", "Shopping", "Hiking", "Safari", "Luxury", "Festival", "Spiritual", "Wellness & Spa", "Cruise", "Extreme Sports", "Winter Sports", "Desert Safari", "Urban Exploration", "Island Hopping", "Agritourism", "Wine & Vineyard", "Architecture", "Museum & Art", "National Parks", "Family Friendly", "Solo Travel", "Youth Travel", "Educational Tours", "Bird Watching", "Fishing & Angling", "Volunteer Tourism", "Road Trips", "Caving", "Sports Events", "Music & Concerts", "Science & Technology", "Language Immersion", "Space Tourism", "Backpacking", "Heritage Sites", "Meditation & Yoga", "Railway Journeys", "Pet-Friendly Tours", "Golf Tours", "Cycling", "Kayaking", "Sailing", "Rafting", "Glamping", "National Heritage", "Off-Roading", "Ziplining", "Haunted & Paranormal", "Underwater Diving", "Rock Climbing", "Forest Retreats", "Vegan & Organic Food Tours", "Health & Detox Retreats", "Horseback Riding", "Ancient Ruins", "Pilgrimages", "Bungee Jumping", "Botanical Gardens", "Cycling & Bike Tours", "Sunset Cruises", "Luxury Train Journeys", "Stargazing", "Chasing Northern Lights", "Mangrove Exploration", "Agricultural Fair Visits", "Tea & Coffee Plantation Tours", "Marine Wildlife Watching"]
const Create_Tour = () => {
  const role=useSelector((state)=>state.auth.login.role);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [cities, setCities] = useState([]);
  const [images, setImages] = useState([]); 

  // Individual state variables
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fromcity, setFromCity] = useState('');
  const [tocity, setToCity] = useState('');
  const [distance, setDistance] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [postcode, setPostcode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [fromlatitude, setfromLatitude] = useState('');
  const [fromlongitude, setfromLongitude] = useState('');
  const [duration, setDuration] = useState('');
  const [fromdate, setFromDate] = useState('');
  const [time, settime] = useState('');
  const [todate, setToDate] = useState('');
  const [availableseats, setAvailableSeats] = useState('');
  const [category, setCategory] = useState([{ title: '', description: '' }]);
  const [tags, setTags] = useState(['']);
  const [admin,setAdmin] = useState();
  const [company, setCompany] = useState('');
  useEffect(() => {
    if (role === "admin") {
      setAdmin(role);
    } else if (role === "company") {
      setCompany(role);
    }
  }, [role]);
  useEffect(() => {
     axios.get(`https://www.tripwaly.com/api/tour/city/get`)
      .then(response => {
        const cityData = response.data[0].city;
        const cityOptions = cityData.map(city => ({ value: city, label: city }));
        setCities(cityOptions);
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages([...images, file]);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    // Date validation
    const fromdateObj = new Date(fromdate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison
  
    if (fromdateObj < today) {
      toast.error("Date should be today or a future date");
      return;
    }
  
    // Prepare tags array with specific indices
    const updatedTags = [];
    updatedTags[0] = title;
    updatedTags[1] = fromcity;
    updatedTags[2] = tocity;
    updatedTags[3] = description;
  
    const formDataToSend = new FormData();
    // Append individual fields to formData
    formDataToSend.append('title', title);
    formDataToSend.append('description', description);
    formDataToSend.append('fromcity', fromcity);
    formDataToSend.append('tocity', tocity);
    formDataToSend.append('distance', distance);
    formDataToSend.append('location', location);
    formDataToSend.append('time', time);
    formDataToSend.append('price', price);
    formDataToSend.append('postcode', postcode);
    formDataToSend.append('latitude', latitude);
    formDataToSend.append('longitude', longitude);
    formDataToSend.append('fromlatitude', fromlatitude);
    formDataToSend.append('fromlongitude', fromlongitude);
    formDataToSend.append('duration', duration);
    formDataToSend.append('fromdate', fromdate);
    formDataToSend.append('todate', todate);
    // Append each tag separately with its index
    updatedTags.forEach((tag, index) => {
      formDataToSend.append(`tags[${index}]`, tag);
    });
    formDataToSend.append('availableseats', availableseats);
    formDataToSend.append('admin', admin);
    formDataToSend.append('company', company);
  
    // Append category array to formData
    category.forEach((cat, index) => {
      formDataToSend.append(`category[${index}][title]`, cat.title);
      formDataToSend.append(`category[${index}][description]`, cat.description);
    });
    
    // Append images to formData
    images.forEach((image) => {
      formDataToSend.append('images', image);
    });
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://www.tripwaly.com/api/tour/create`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const createdTour = response.data;
      dispatch(setTourid(createdTour._id));
      toast.success('Tour created successfully!');
      navigate('/admin/dashboard/create/tour/schedule');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        if (errorMessage === 'A tour with this title already exists') {
          toast.error('A tour with this title already exists');
        } else {
          toast.error(`Error creating tour: ${errorMessage}`);
        }
      } else {
        toast.error(`Error creating tour: ${error.message}`);
      }
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setCategory(selectedOptions ? selectedOptions.map(option => ({ title: option.value, description: '' })) : []);
  };
  
  const handleAddCategory = () => {
    setCategory([...category, { title: '', description: '' }]);
  };
  const handleRemoveCategory = (index) => {
    setCategory(category.filter((_, i) => i !== index));
  };
  return (
    <div className="container mt-4">
      <ToastContainer />
      <h4 className="text-center mb-4">Create Tour</h4>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Tour Fields */}
        <div className="row">
          <div className="col-md-6 mb-2">
            <label>Title</label>
            <input
            required
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            
            autoComplete='on'
        
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Description</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
     required       
            autoComplete='on'
            />
          </div>
        </div>
        <div className='row'>
          <div className="col-md-6 mb-3">
            <Select
              options={cities}
              value={fromcity ? { value: fromcity, label: fromcity } : null}
              onChange={(option) => setFromCity(option ? option.value : '')}
             required
              autoComplete='on'
              placeholder="Select a from city"
            />
          </div>
          <div className="col-md-6 mb-3">
            <Select
              options={cities}
              value={tocity ? { value: tocity, label: tocity } : null}
              onChange={(option) => setToCity(option ? option.value : '')}
             required
              autoComplete='on'
              placeholder="Select a to city"
            />
          </div>
        </div>
        {/* Distance Location */}
       
        {/* <div className='row'>
        <div className="col-md-6 mb-3">
        <label>Distance</label>
            <input
            required
              type="text"
              className="form-control"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Location</label>
            <input
            required
              type="text"
              className="form-control"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              
            autoComplete='on'
            />
          </div>
        </div> */}
        
        {/* Price, postcode */}
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Price</label>
            <input
            required
              type="number"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Duration</label>
            <input
            required
              type="text"
              className="form-control"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              
            autoComplete='on'
            />
          </div>
       
          {/* <div className="col-md-6 mb-3">
            <label>Postcode</label>
            <input
            required
              type="number"
              className="form-control"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              
            autoComplete='on'
            />
          </div> */}
        </div>
       
        {/*latitude , longitude */} 
        {/* <div className="row">
          <div className="col-md-6 mb-3">
            <label>Latitude</label>
            <input
            required
              type="number"
              className="form-control"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Longitude</label>
            <input
            required
              type="number"
              className="form-control"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
        </div> */}
       
        {/*latitude , longitude */}
       
        {/* <div className="row">
          <div className="col-md-6 mb-3">
            <label>From City Latitude</label>
            <input
            required
              type="number"
              className="form-control"
              value={fromlatitude}
              onChange={(e) => setfromLatitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>From City Longitude</label>
            <input
            required
              type="number"
              className="form-control"
              value={fromlongitude}
              onChange={(e) => setfromLongitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
        </div> */}
       
        {/* Duration */}
      
       {/* From and to date */}
       <div className="row">
        
          <div className="col-md-6 mb-3">
            <label>From Date</label>
            <input
            required
              type="date"
              className="form-control"
              value={fromdate}
              onChange={(e) => setFromDate(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>To Date</label>
            <input
            required
              type="date"
              className="form-control"
              value={todate}
              onChange={(e) => setToDate(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          
       
        </div>
        {/* Time seats*/}
        <div className="row">
         
        <div className="col-md-6 mb-3">
           <label>Time</label>
           <input
           required
             type="time"
             className="form-control"
             value={time}
             onChange={(e) => settime(e.target.value)}
             
           autoComplete='on'
           />
         </div>
         <div className="col-md-6 mb-3">
           <label>Available Seats</label>
           <input
           required
             type="number"
             className="form-control"
             value={availableseats}
             onChange={(e) => setAvailableSeats(e.target.value)}
             
           autoComplete='on'
           />

         </div>
      </div>
        {/* Categories */}
        <div className="mb-3 d-flex justify-content-between">
        <div className='row-col-7 w-100'>
  <label>Categories</label>
  {category.map((cat, index) => (
    <div className="d-flex gap-4" key={index}>
      <div className="col-md-5 w-50 mb-2 position-relative">
        <select
          required
          className="form-control w-100"
          onChange={handleCategoryChange}
          placeholder="Select categories"
        >
          <option value="">Select Category</option>
          {categorydata
            .filter((item) => !category.some((c) => c.title === item)) // exclude selected items
            .map((item, idx) => (
              <option key={idx} value={item}>
                {item}
              </option>
            ))}
        </select>
        <div className="dropdown-icon-container">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>

      <div className="d-flex gap-1">
        <button
          type="button"
          className="btn btn-styling"
          onClick={() => handleRemoveCategory(index)}
        >
          Remove
        </button>
        <button
          type="button"
          className="btn btn-styling"
          onClick={handleAddCategory}
        >
          Add
        </button>
      </div>
    </div>
  ))}
</div>

      <div className="form-group mt-4">
  <label htmlFor="customFileInput" className="custom-file-label">
    <FontAwesomeIcon icon={faUpload} className="upload-icon" />
    <span>Upload Image</span>
  </label>
  <input
    required
    type="file"
    id="customFileInput"
    accept="image/*"
    onChange={handleImageChange}
    className="custom-file-input"
  />
</div>


        
    </div>
        {/* Tags */}
        {/* <div className="mb-3">
          <label>Tags</label>
          {tags.map((tag, index) => (
            <div className="row mb-3" key={index}>
              <div className="col-md-10">
                <input
                required
                  type="text"
                  className="form-control"
                  placeholder="Tag"
                  value={tag}
        
        autoComplete='on'      
        onChange={(e) => {
                    const newTags = [...tags];
                    newTags[index] = e.target.value;
                    setTags(newTags);
                  }}
                />
              </div>
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    const newTags = tags.filter((_, i) => i !== index);
                    setTags(newTags);
                  }}
                >
                  Remove Tag
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setTags([...tags, ''])}
          >
            Add Tag
          </button>
        </div> */}
        {/* Images */}

        {/* Submit Button */}
        <div className='items-center'>
        <button type="submit" className="btn btn-secondary">Create Tour</button>
        </div>
      </form>
    </div>
  );
};
export default Create_Tour;
